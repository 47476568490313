<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      {{ pageTitle }}
    </v-card-title>
    <v-card-text class="d-flex align-items-center">
      <div
        class="table-v-action-button mr-3"
        @click="getProjecoes()"
      >
        <v-icon>mdi-chart-timeline-variant-shimmer</v-icon> Projeção
      </div>
      <v-menu offset-y dense max-width="160px">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="table-v-action-button mr-2">
            <v-icon>mdi-file-export-outline</v-icon> Exportação
          </div>
        </template>
        <v-list>
          <v-list-item style="cursor: pointer" @click="handleExportCsv()">
            <v-list-item-title class="v-label-input">
              <v-icon left>mdi-file-delimited-outline</v-icon> Exportar CSV
            </v-list-item-title>
          </v-list-item>
          <v-list-item style="cursor: pointer" @click="handleExportXlsx()">
            <v-list-item-title class="v-label-input">
              <v-icon left>mdi-file-excel-outline</v-icon> Exportar XLSX
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <year-select
        class="table-v-action-button mr-3"
        style="max-width: 150px"
        @change="doLoad($event)"
      ></year-select>
    </v-card-text>
    <table-v
      :cols="obrigacaoCols"
      :disablePagination="true"
      :hasExportCSV="false"
      :hasFilter="false"
      :opts="opts"
      :rows="obrigacaoRows"
    ></table-v>
    <table-v
      :cols="obrigacaoCols"
      :disablePagination="true"
      :hasExportCSV="false"
      :hasFilter="false"
      :opts="opts"
      :rows="creditoRows"
    ></table-v>
    <table-v
      :cols="obrigacaoCols"
      :disablePagination="true"
      :hasExportCSV="false"
      :hasFilter="false"
      :opts="opts"
      :rows="investimentoRows"
    ></table-v>
    <form-modal
      :cols="projecaoFields"
      :maxWidth="600"
      :opened.sync="showProjecaoModal"
      :opts="opts"
      title="Editar Projeções"
      :value.sync="projecaoFormValue"
      @save="saveProjecao"
    ></form-modal>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

// Ordem das obrigações é importante
const obrigacoes = [
  {
    key: "fat_bruto",
    name: "Faturamento Bruto",
  },
  {
    key: "fat_liquido",
    name: "Faturamento Líquido",
  },
  {
    key: "obrigacao4",
    name: "Obrigação 4%",
  },
  {
    key: "total_obrigacao",
    name: "Obrigação Total",
  },
];

// Ordem das divisões é importante
const creditos = [
  {
    key: "investimento_total",
    name: "Investimento em P&D Total",
    // totalFat: true,
  },
  {
    key: "saldo_ped",
    name: "Sobra de P&D",
  },
  {
    key: "valor_credito",
    name: "Valor do Crédito",
  },
];

// Ordem dos investimentos é importante
const investimentos = [
  {
    key: "total",
    name: "Investimento em P&D",
  },
  {
    key: "FNDCT",
    name: "FNDCT",
  },
  {
    key: "FACTI_IncisoIII",
    name: "FACTI - Inciso III (FNDCT)",
  },
  {
    key: "FACTI_IncisoIV",
    name: "FACTI - Inciso VI",
  },
  {
    key: "convenio_NNE_publica",
    name: "Convênio N/NE Pública",
  },
  {
    key: "convenio_NNE_privada",
    name: "Convênio N/NE Privada",
  },
  {
    key: "convenio_outras_regioes",
    name: "Convênio Outras Regiões",
  },
  {
    key: "ped_interno",
    name: "P&D Interno",
  },
];

export default {
  components: {
    "form-modal": () => import("@/components/form-modal.vue"),
    "table-v": () => import("@/components/table-v.vue"),
    "year-select": () => import("@/components/year-select.vue"),
  },
  computed: {
    ...mapGetters(["anoBase", "clientId"]),
    obrigacaoCols: function () {
      return [
        {
          key: "legend",
          name: "",
          sortable: false,
          width: "25%",
        },
        {
          key: "tri1",
          name: "1 tri",
          sortable: false,
          type: this.$fieldTypes.MONEY,
          icon: this.parseIcon(1),
          width: "15%",
        },
        {
          key: "tri2",
          name: "2 tri",
          sortable: false,
          type: this.$fieldTypes.MONEY,
          icon: this.parseIcon(2),
          width: "15%",
        },
        {
          key: "tri3",
          name: "3 tri",
          sortable: false,
          type: this.$fieldTypes.MONEY,
          icon: this.parseIcon(3),
          width: "15%",
        },
        {
          key: "tri4",
          name: "4 tri",
          sortable: false,
          type: this.$fieldTypes.MONEY,
          icon: this.parseIcon(4),
          width: "15%",
        },
        {
          key: "total",
          name: "TOTAL",
          sortable: false,
          type: this.$fieldTypes.MONEY,
          width: "15%",
        },
      ];
    },
    creditoRows: function () {
      if (Array.isArray(this.resumoCredito?.credito)) {
        const parsed = this.resumoCredito.totalFat
          ? creditos
          : creditos.filter(({ totalFat }) => !totalFat);
        return parsed.map(({ key, name }) =>
          this.resumoCredito.credito.reduce(
            (prev, curr) => ({
              ...prev,
              [`tri${curr.trimestre}`]: curr[key],
              total: prev.total + curr[key],
            }),
            {
              legend: name,
              tri1: 0,
              tri2: 0,
              tri3: 0,
              tri4: 0,
              total: 0,
            }
          )
        );
      }

      return [];
    },
    exportOptions: function () {
      return {
        anoBase: this.anoBase,
        styles: [1, 7, 12].reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              font: {
                bold: true,
              },
            },
          }),
          {}
        ),
      };
    },
    investimentoRows: function () {
      if (this.resumoCredito?.relatorioRDA === 'completo') {
        investimentos[7].name = "P&D Interno Completo";
      } else {
        investimentos[7].name = "P&D Interno Simplificado";
      }

      if (Array.isArray(this.resumoCredito?.investimento_ped)) {
        const investimento = investimentos.map(({ key, name }) =>
          this.resumoCredito.investimento_ped.reduce(
            (prev, curr) => ({
              ...prev,
              [`tri${curr.trimestre}`]: curr[key],
              total: prev.total + curr[key],
            }),
            {
              legend: name,
              tri1: 0,
              tri2: 0,
              tri3: 0,
              tri4: 0,
              total: 0,
            }
          )
        );
        const investimentoDiff = investimentos
          .map(({ key, name }) =>{
            const keyDiff = `${key}_dif`;
            return this.resumoCredito.investimento_ped.reduce(
              (prev, curr) => ({
                ...prev,
                [`tri${curr.trimestre}`]: curr[keyDiff],
                total: prev.total + curr[keyDiff],
              }),
              {
                legend: `${name} ref ${this.anoBase - 1} pago ${this.anoBase}`,
                tri1: 0,
                tri2: 0,
                tri3: 0,
                tri4: 0,
                total: 0,
              }
            )
          })
          .filter(({ total }) => total > 0);
        return investimento.concat(investimentoDiff);
      }

      return [];
    },
    obrigacaoRows: function () {
      if (Array.isArray(this.resumoCredito?.obrigacao)) {
        return obrigacoes.map(({ key, name }) =>
          this.resumoCredito.obrigacao.reduce(
            (prev, curr) => ({
              ...prev,
              [`tri${curr.trimestre}`]: curr[key],
              total: prev.total + curr[key],
            }),
            {
              legend: name,
              tri1: 0,
              tri2: 0,
              tri3: 0,
              tri4: 0,
              total: 0,
            }
          )
        );
      }

      return [];
    },
    obrigacoesRealizadas: function () {
      const base = {
        tri1: false,
        tri2: false,
        tri3: false,
        tri4: false,
      };

      if (Array.isArray(this.resumoCredito?.obrigacao)) {
        return this.resumoCredito.obrigacao.reduce(
          (prev, { trimestre, realizado }) => ({
            ...prev,
            [`tri${trimestre}`]: realizado,
          }), base);
      }

      return base;
    },
    projecaoFields: function () {
      if (!this.anoBase) {
        return [];
      }

      return Array(12).fill(`${this.anoBase}-XX`).reduce((acc, comp, index) => {
        const competencia = comp.replace('XX', `${index + 1}`.padStart(2, 0));
        const month = this.$options.filters.toFullMonth(competencia);

        return [
          ...acc,
          {
            key: competencia,
            name: `Valor de ${month}`,
            type: this.$fieldTypes.MONEY,
            colSize: 4,
          },
        ];
      }, []);
    },
    relatorioParaExportacao: function () {
      const header = this.obrigacaoCols.map(({ name }) => name);
      const tables = [this.obrigacaoRows, this.creditoRows, this.investimentoRows];
      const cabecalho = [
        // ["Título", this.pageTitle, "Ano", this.anoBase],
        ["Ano", this.anoBase],
        [],
      ];
      const corpo = tables.reduce((prev, curr) => {
        const rows = curr.reduce((prevRow, currRow) => {
          const parsedRow = this.obrigacaoCols.map(({ key }) => currRow[key] || 0);
          return [...prevRow, parsedRow];
        }, []);

        return [...prev, header, ...rows, []];
      }, []);

      return [...cabecalho, ...corpo];
    },
    resource: function () {
      return this.apiResource(
        `/v1/faturamento/creditoObrigacao/${this.clientId}`
      );
    },
    resourceProjecao: function () {
      return this.apiResource(
        `/v1/faturamento/projecao/${this.clientId}`
      );
    },
    pageTitle: function () {
      return this.$route.meta.name;
    },
  },
  data: function () {
    return {
      resumoCredito: {},
      showProjecaoModal: false,
      projecaoFormValue: {},
      projecoes: [],
      opts: {},
    };
  },
  methods: {
    doLoad: function (anoBase) {
      if (!this.clientId) {
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "error",
          title: "Sem empresa selecionada.",
          text: "Selecione uma empresa no cabeçalho antes de acessar esse relatório.",
        });
        this.$router.push({ name: 'homepage' });
        return;
      }

      const query = `anoBase=${anoBase}`;
      return this.resource.get({ query }).then((response) => {
        if (response.error) {
          this.resumoCredito = {};
        } else {
          this.resumoCredito = response;
        }

        return response;
      });
    },
    getProjecoes: function () {
      const query = this.anoBase ? `anoBase=${this.anoBase}` : '';
      this.resourceProjecao.get({ query }).then((response) => {
        if (response.error) {
          console.error(response.error);
          return;
        }

        if (Array.isArray(response)) {
          this.projecaoFormValue = response.reduce((acc, { competencia, valor }) => ({
            ...acc,
            [competencia]: valor,
          }), {});
          this.showProjecaoModal = true;
          this.projecoes = response;
        }
      });
    },
    handleExportCsv: function () {
      this.exportCsv(this.relatorioParaExportacao);
    },
    handleExportXlsx: function () {
      this.exportXlsx2(this.relatorioParaExportacao.slice(2), this.exportOptions);
    },
    parseIcon: function (trimestre) {
      if (!trimestre) {
        return null;
      }

      const realizado = this.obrigacoesRealizadas[`tri${trimestre}`];
      return {
        text: realizado ? 'mdi-chart-timeline-variant' : 'mdi-chart-timeline-variant-shimmer',
        style: `margin-left: .5rem; color: ${realizado ? '#007bff' : '#6c757d7a'}`,
        title: realizado ? 'Realizado' : 'Projetado',
      };
    },
    saveProjecao: function (formValue, closeFn) {
      const body = Object.entries(formValue).map(([competencia, valor]) => {
        const id = this.projecoes.find((projecao) => projecao.competencia === competencia)?.id || null;
        return {
          id,
          competencia,
          valor,
        };
      });
      this.resourceProjecao.save(body).then((response) => {
        if (!response.error) {
          if (closeFn) {
            closeFn();
          }

          this.$router.go(0);
        }
      });
    },
  },
  watch: {
    clientId: function () {
      this.doLoad(this.anoBase);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table {
    border-radius: 0px !important;
  }

  .v-data-table__wrapper {
    height: unset !important;
  }
}

.teros-elevation.v-card.v-sheet.theme--light {
  margin-bottom: 1rem;
}
</style>
